import axios from 'src/utils/axios'
import { LectureInList, Lecture, PaginationMeta, LectureInSkill, NextLecture } from 'src/types/common'
import { ReportType } from 'src/components/ui_parts/chikamichi/common/problem_report_modal'

export type TrainingContentLecturesSearch = {
  page?: number
  per?: number
  trainingTypeId?: number | null
}

export type TrainingContentLecturesResponseType = {
  lectures: LectureInList[]
  meta: PaginationMeta
}

export type RouteType = 'dx_assessment' | 'literacy_assessment' | 'normal_lecture' | 'last_exam' | 'others'

export type ProblemReport = {
  reportType?: ReportType
  description: string
  questionId?: number
  routeType: RouteType
  originalReportedUrl?: string
}

export const index = async ({
  trainingContentId,
  params
}: {
  trainingContentId: string | number
  params: TrainingContentLecturesSearch
}): Promise<TrainingContentLecturesResponseType> => {
  const { data } = await axios.get(`/account/training_contents/${trainingContentId}/lectures`, {
    params: {
      ...params,
      'q[training_step_id_eq]': params.trainingTypeId
    }
  })

  return data
}

export const show = async ({
  trainingContentId,
  lectureId
}: {
  trainingContentId: string | number
  lectureId: string | number
}): Promise<{ lecture: LectureInSkill }> => {
  const { data } = await axios.get(`/account/training_contents/${trainingContentId}/lectures/${lectureId}`)

  return data
}
export const start = async ({
  trainingContentId,
  lectureId
}: {
  trainingContentId: string | number
  lectureId: string | number
}): Promise<{ lecture: Lecture }> => {
  const { data } = await axios.post(`/account/training_contents/${trainingContentId}/lectures/${lectureId}/starts`)

  return data
}
export const sendProblemReport = async (reportData: ProblemReport) => {
  await axios.post('/account/problem_reports', { problemReport: reportData })
}

export const getNextLectures = async (trainingContentCategoryId?: number): Promise<NextLecture[]> => {
  const { data } = await axios.get(`/account/lectures/nexts`, {
    params: {
      // TODO: ※ For future extensions, we are using the list API.
      page: 1,
      per: 1,
      training_content_category_id: trainingContentCategoryId || undefined
    }
  })

  return data.lectures
}
